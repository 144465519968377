<template>
  <Stepper
    :subtitle="$tc('JOB.TITLE', selected.length).toUpperCase()"
    :steps="steps"
    :show="show"
    :icon="$t('JOB.ICON')"
    @close="(v) => $emit('close', v)"
    @create="(v) => $emit('create', v)"
  />
</template>
<script>
import Stepper from "@/components/Stepper";

export default {
  name: "FormJobStepper",
  components: {
    Stepper,
  },
  data: (_this) => ({
    steps: [
      {
        required: false,
        title: _this.$i18n.t("JOB.STEP_INTRO_TITLE"),
        description: _this.$i18n.t("JOB.STEP_INTRO_DESCRIPTION"),
        component: "forms/job/stepper/FormJob.step.intro",
      },
      {
        required: false,
        title: _this.$i18n.t("JOB.STEP_CONTRACT_TITLE"),
        description: _this.$i18n.t("JOB.STEP_CONTRACT_DESCRIPTION"),
        component: "forms/job/stepper/FormJob.step.contract",
      },
      {
        required: false,
        title: _this.$i18n.t("JOB.STEP_COMPANY_SPECIFICATION_TITLE"),
        description: _this.$i18n.t("JOB.STEP_COMPANY_SPECIFICATION_DESCRIPTION"),
        component: "forms/job/stepper/FormJob.step.specification",
      },
      {
        required: false,
        title: _this.$i18n.t("JOB.STEP_CONFIRM_TITLE"),
        description: _this.$i18n.t("JOB.STEP_CONFIRM_DESCRIPTION"),
        component: "forms/job/stepper/FormJob.step.confirm",
      },
    ],
  }),
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
