<template>
  <div class="page page-xtrading-all-jobs">
    <PageHeader
    :icon="$t('JOB.ICON')"
    :title="$t('JOB.TITLES')"
    >
      <template slot="nav">
        <small><strong>{{$tc('JOB.TITLE_COUNT',count)}}</strong></small>
      </template>

      <!-- <v-menu
        offset-y
        nudge-bottom="5px"
        :close-on-click="true"
      >
        <template v-slot:activator="{ on: onMenu, attrs: attrsMenu }">
          <v-tooltip bottom transition="none">
            <template v-slot:activator="{ on: onTip, attrs: attrsTip }">
              <v-btn
                fab
                small
                depressed
                color="primary"
                v-bind="{...attrsMenu, ...attrsTip}"
                v-on="{...onMenu, ...onTip}"
              >
                <v-icon>mdi-chevron-double-down</v-icon>
              </v-btn>
            </template>
            {{ $t('COMMON.MORE_ACTIONS') }}
          </v-tooltip>
        </template>

        <v-list nav dense>
          <v-list-item link @click="newItem">
            <v-list-item-icon><v-icon size="20">mdi-plus</v-icon></v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title class="text-capitalize">
                <strong>{{ $t('COMMON.NEW') }}</strong>
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu> -->

      <v-btn
        id="xtrading_jobs-addjob"
        small
        depressed
        color="success"
        class="xtrading_jobs-addjob page-xranking-all-jobs__button-upload ml-2"
        @click="newItem"
      >
        <v-icon>mdi-plus</v-icon>
        {{ $t('COMMON.ADD_JOB') }}
      </v-btn>

    </PageHeader>


    <FormJobStepper
      :show="showNew"
      @close="showNew = false"
      @create="updateItems"
    />

    <Side
      component="forms/job_publication/compact/FormJobPublicationCompact"
      :ids="(selected || []).map(v => v.id)"
      :props="{createCampaignJobs: true}"
      :value="showPublish"
      @close="closePublish"
    />

    <Side
      component="forms/job/compact/FormJobCompact"
      :ids="(selected || []).map(v => v.id)"
      :value="showEdit"
      @close="closeEdit"
      @create="loadItems"
      @update="loadItems"
      @remove="removeItems"
    />
    <DialogRemove
      :value="showDialogRemove"
      :items="selected"
      item-text="id"
      @confirm="confirmRemoveItems"
      @close="showDialogRemove = false"
      @select="selectItems"
    />
    <List
      :items="items"
      :count="count"
      :offset="offset"
      :limit="limit"
      :pagination="true"
      :sortable="['updated_at', 'created_at', 'title']"
      defaultSort="updatedat_desc"
      :loading="loading"
      :selected="selected"
      @applyFilters="applyFilters"
      @resetFilters="resetFilters"
      @select="selectItems"
      @page="changePage"
      @click="selectItem"
      @dblclick="editItem"
      @reload="searchItems"
      @sort="sortBy"
    >

    <template v-slot:header="">
      <v-col cols="4">
        <v-text-field
          ref="where.title"
          outlined
          hide-details
          dense
          clearable
          prepend-inner-icon="mdi-magnify"
          :placeholder="$t('COMMON.SEARCH')"
          @keyup="searchItems"
          @click:clear="searchItems"
        />
      </v-col>
      <v-col cols="2" class="pl-0">
        <v-select
          ref="where.status_type"
          :items="$t('JOB.STATUS_TYPE_ITEMS')"
          :label="$t('JOB.STATUS')"
          item-text="text"
          item-value="value"
          clearable
          multiple
          outlined
          hide-details
          dense
          @change="searchItems"
          @click:clear="searchItems"
        />
      </v-col>
      <v-col cols="2" class="pl-0">
        <v-select
          ref="where.feed_id"
          :items="feedItems"
          item-text="title"
          item-value="id"
          hide-details
          outlined
          dense
          clearable
          multiple
          :prepend-inner-icon="$t('FEED.ICON')"
          :label="$t('FEED.TITLE')"
          @change="searchItems"
          @click:clear="searchItems"
        />
      </v-col>
    </template>

    <template v-slot:filters="">
      <div class="pa-4">
        <v-row>
          <v-col>
            <v-checkbox
              ref="where.parent_id"
              :label="$t('JOB.PARENT')"
              :value="null"
              hide-details
              class="mt-0"
              @change="searchItems"
            />
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col>
            <span class="text-small text-uppercase grey--text">{{ $tc("INPUT.LOCATION_CITY") }}</span>
            <v-autocomplete
              ref="where.location_city"
              v-model="cities"
              :search-input.sync="locationInput"
              multiple
              outlined
              dense
              clearable
              :items="cityItemsMerged"
              item-text="name"
              :loading="citiesIsBusy"
              return-object
              small-chips
              hide-details
              @change="searchItems"
              @click:clear="searchItems"
              hide-no-data
            >
              <template v-slot:item="{ index, item }">
                {{item.name}}<small class="grey--text ml-2 numeric">{{item.code}}, <span class="text-uppercase">{{item.country_code}}</span></small>
              </template>
            </v-autocomplete>
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col>
            <span class="text-small text-uppercase grey--text">{{ $tc("INPUT.COMPANY") }}</span>
            <v-text-field
              ref="where.company_title"
              clearable
              outlined
              dense
              hide-details
              @keyup="searchItems"
              @click:clear="searchItems"
            />
          </v-col>
        </v-row>

        <v-row class="mt-0">
          <v-col>
            <span class="text-small text-uppercase grey--text">{{ $tc("INPUT.OCCUPATIONS") }}</span>
            <v-combobox
              ref="where.xtramile_occupation_id"
              @click:clear="searchItems"
              @change="searchItems"
              small-chips
              clearable
              multiple
              dense
              hide-details
              :placeholder="$t('INPUT.MULTIPLE_VALUE')"
              :items="occupations"
              item-text="title"
              item-value="id"
              outlined
            />
          </v-col>
        </v-row>

        <v-row class="mt-0 mb-4">
          <v-col>
            <span class="text-small text-uppercase grey--text">{{ $tc("INPUT.SECTOR") }}</span>
            <v-combobox
              ref="where.xtramile_sector_id"
              @click:clear="searchItems"
              @change="searchItems"
              small-chips
              clearable
              multiple
              dense
              hide-details
              :placeholder="$tc('INPUT.SECTOR')"
              :items="sectors"
              item-text="title"
              item-value="id"
              outlined
            />
          </v-col>
        </v-row>

        <v-divider class="mb-4" />
        <p>{{$t('FEED.FILEFORMAT_CUSTOM_TITLE')}}</p>
        <div v-for="(cfilter, i) in customFilters" :key="'blbn'+i">
          <v-row class="mt-0">
            <v-col>
              <span class="text-small text-uppercase grey--text">{{ cfilter }}</span>
              <v-text-field
                :ref="'where.custom.' + cfilter"
                clearable
                outlined
                dense
                hide-details
                @keyup="searchItems"
                @click:clear="searchItems"
              />
            </v-col>
          </v-row>
        </div>

      </div>
    </template>


      <template v-slot:empty="">
        <div class="ml-10 mt-6 text-center">
          <div class="text-h5 mt-10 mb-4">{{$t('LIST.NO_RESULT')}}</div>
          <template v-if="$queryFilters().count() > 0">
            <div><small class="grey--text">{{$t('LIST.SUGGESTION')}}</small></div>
            <div><a class="info--text" @click="clearFilters">{{$t('LIST.CLEAR_FILTER')}}</a></div>
          </template>
        </div>
      </template>

      <template v-slot:selections="">
        <v-col cols="12" class="pt-0 pl-0">
          <div><small><strong>{{ $tc('COMMON.SELECTED', +selected.length) }}</strong></small></div>

            <v-btn
              class="mr-2"
              small
              depressed
              color="primary"
              @click="selectAllPages"
            >
              <v-icon size="16" class="mr-1">mdi-playlist-plus</v-icon>
              {{ $t('COMMON.SELECT_ALL_PAGES') }}
            </v-btn>

            <v-btn
              class="mr-2"
              small
              depressed
              color="primary"
              @click="showPublish = true"
            >
              <v-icon size="16" class="mr-1">mdi-forwardburger</v-icon>
              {{ $t('COMMON.PUBLISH') }}
            </v-btn>

            <v-btn
              class="mr-2"
              small
              depressed
              color="primary"
              @click="showEdit = true"
            >
              <v-icon size="16" class="mr-1">mdi-pencil</v-icon>
              {{ $t('COMMON.EDIT') }}
            </v-btn>

            <v-btn
              class="mr-2"
              small
              depressed
              color="error"
              @click="showDialogRemove = true"
            >
              <v-icon size="16" class="mr-1">mdi-delete</v-icon>
              {{ $t('COMMON.REMOVE') }}
            </v-btn>
            <v-btn
              class="mr-2"
              small
              depressed
              color="secondary black--text"
              @click="selectItems([])"
            >
              <v-icon size="16" class="mr-1" color="black">mdi-close</v-icon>
              {{ $t('COMMON.CANCEL') }}
            </v-btn>

        </v-col>
      </template>

      <template v-slot:item-list="item">

        <v-list-item-content>
          <v-list-item-subtitle class="numeric text-small">
            <span class="mr-2 primary--text" v-if="item.company_title || item.company">{{item.company_title || item.company}}</span>
          </v-list-item-subtitle>
          <v-list-item-title class="primary--text position-relative">
            <strong :class="[item.draft ? 'grey--text' : '']">{{item.title}}</strong>
          </v-list-item-title>
          <v-list-item-subtitle class="text-small numeric">

            <div class="d-flex">
              <div v-if="item.feed_id" style="width: 140px; overflow:hidden; text-overflow:ellipsis;">
                <v-icon class="mr-1" size="12" >mdi-map-marker</v-icon>
                <span v-if="item.location"><strong>{{ `${item.location_city}, ${item.location_city_code || ''}` }}</strong></span>
                <span v-else><strong>{{ $t('COMMON.NOT_FOUND.LOCATION') }}</strong></span>
              </div>

              <div class="mr-1" v-if="item.feed_id">
                <v-icon class="mr-1" size="12" >{{$t('FEED.ICON')}}</v-icon>
                <span>{{item.feed.title}}</span>
              </div>

              <div class="error--text ml-1" v-if="getReportCount(item)">
                <v-icon class="mr-1" size="12" color="error" >mdi-alert</v-icon>
                <span>{{$tc('JOB.STATUS_ERROR_COUNT', getReportCount(item) || 10)}}</span>
              </div>

              <div class="ml-1" v-if="item.parent_id" >
                <v-icon class="mr-1" size="12">{{ $t('COMMON.DUPLICATE_ICON') }}</v-icon>
                <span>{{$t('JOB.DUPLICATED')}}</span>
              </div>

              <div class="pink--text ml-1" v-if="item.status === 0" >
                <v-icon class="mr-1" size="12" color="pink" >mdi-archive</v-icon>
                <span>{{$t('JOB.ARCHIVED')}}</span>
              </div>

              <div class="error--text ml-1" v-if="item.draft" >
                <v-icon class="mr-1" size="12" color="error" >mdi-pencil</v-icon>
                <span>{{$t('JOB.DRAFT')}}</span>
              </div>
            </div>

          </v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action class="ml-2">
          <status-label v-if="item.status_type" icon-size="14" :item="item" small />
          <div class="date" v-else>{{ $moment(item.updated_at).fromNow() }}</div>
        </v-list-item-action>
        <v-list-item-action id="xtrading-jobs-side_panel" class="xtrading-jobs-side_panel">
          <div id="xtrading-jobs-side_panel-buttons" class="xtrading-jobs-side_panel-buttons">
            <v-btn
              id="xtrading-jobs-side_panel-deletebutton"
              icon
              class="xtrading-jobs-side_panel-deletebutton ml-2"
              @click.stop="removeItem(item)"
            >
              <v-icon
                id="xtrading-jobs-side_panel-deleteicon"
                class="xtrading-jobs-side_panel-deleteicon"
              >
                mdi-delete-outline
              </v-icon>
            </v-btn>
            <a
              id="xtrading-jobs-side_panel-openinnewlink"
              class="xtrading-jobs-side_panel-openinnewlink ml-2"
              :href="item.url"
              :title="item.url"
              target="blank"
            >
              <v-btn
                icon
                id="xtrading-jobs-side_panel-openinnewbutton"
                class="xtrading-jobs-side_panel-openinnewbutton"
              >
                <v-icon
                  id="xtrading-jobs-side_panel-openinnewicon"
                  class="xtrading-jobs-side_panel-openinnewicon"
                >mdi-open-in-new</v-icon>
              </v-btn>
            </a>
            <v-btn
              id="xtrading-jobs-side_panel-editbutton"
              icon
              class="xtrading-jobs-side_panel-editbutton ml-2"
              @click.stop="editItem(item)"
            >
              <v-icon
                id="xtrading-jobs-side_panel-editicon"
                class="xtrading-jobs-side_panel-editicon"
              >
                mdi-pencil-outline
              </v-icon>
            </v-btn>
          </div>
        </v-list-item-action>
      </template>
    </List>
  </div>
</template>

<script>
import Side from '@/components/Side'
import PageHeader from '@/components/PageHeader'
import DialogRemove from '@/components/DialogRemove'
import List from '@/components/List'
import StatusLabel from '@/components/StatusLabel'
import FormJobStepper from '@/forms/job/stepper/FormJobStepper'

export default {
  name: 'PageXtradingAllJobs',
  components: {
    Side,
    PageHeader,
    DialogRemove,
    List,
    StatusLabel,
    FormJobStepper,
  },
  data: (_this) => ({
    statusItems: _this.$i18n.t('STATUS_ITEMS'),
    jobTab: undefined,
    whereValue: {
      status: 1,
      draft: false,
    },
    locationInput: '',
    locationSearchTimeout: 0,
    locationLoading: false,
    sectorItems: [],
    occupationItems: [],
    customFilters:[],
    loading: false,
    items: [],
    count: 0,
    offset: 0,
    limit: 20,
    page: 1,
    selected: [],
    showPublish: false,
    showNew: false,
    showEdit: false,
    showDialogRemove: false,
    showDialogPermissionEdit: false,
    timeout: null,
    status: [1],
    methods: [],
    feedItems: [],
    // filter settings
    categoryItems: [],
    searchCitiesInput: '',
    searchCountriesInput: '',
    countries: [],
    countriesIsBusy: false,
    cities: [],
    cityItems: [],
    citiesIsBusy: false,
    ageRange: [1, 100],
    minAge: 1,
    maxAge: 100,
  }),
  props: ['update', 'select'],

  async created() {
    this.countries = (
      await this.$services.api_programmatic.location_country.search()
    ).data.map(
      ({ name: text, code: value }) => ({ text, value })
    )

    this.loadOccupationsItems()
    this.loadSectorItems()
  },

  watch: {
    update () {
      this.loadItems()
    },
    select (items) {
      this.selected = items
    },
    locationInput (n) {
      this.searchLocationItems(n)
    },
  },

  mounted () {
    this.socket = this.$services.createSocket()
    this.socket.on('notification', (data) => {
      if (data.scope === "job") {
        this.searchItems()
      }
    })
    this.$queryFilters().get()
    this.loadFeedItems()
    this.searchItems()
    if (this.itemId) {
      this.selectItems([{id: this.itemId}])
      this.editSelected()
    }
  },
  beforeDestroy() {
    this.$services.destroySocket(this.socket)
  },

  computed: {
    cityItemsMerged () {
      return this.cityItems.concat(this.cities)
    },
    itemId () {
      return this.$router.currentRoute.query.edit || undefined
    },
    occupations() {
      const occupationsModificatedTitle = this.modificateTitle(this.occupationItems)
      const occupationsSortedByTitle = this.sortArrayByField(occupationsModificatedTitle, 'title')
      return occupationsSortedByTitle
    },
    sectors() {
      return this.sortArrayByField(this.sectorItems, 'title')
    },
  },

  methods: {
    sortArrayByField(arr, sortField) {
      return arr.sort(this.sortFields(sortField))
    },

    sortFields (field) {
      return (a, b) => a[field] > b[field] ? 1 : -1
    },

    capitalizeTitle (title) {
      return title ? `${title[0].toUpperCase()}${title.slice(1)}` : ''
    },

    modificateTitle (items) {
      const itemsClone = JSON.parse(JSON.stringify(items))
      return itemsClone.map(item => ({...item, title: this.capitalizeTitle(item.title)}))
    },

    clearFilters () {
      this.$queryFilters().clear()
      this.loadItems()
    },

    getReportCount (item) {
      const report = item.status_report
      if (report) return Object.keys(report).filter(k => typeof report[k] === 'object').length
      return 0
    },

    onCitySearch (searchCitiesInput) {
      this.searchCitiesInput = searchCitiesInput;
    },

    onCountrySearch (searchCountriesInput) {
      this.searchCountriesInput = searchCountriesInput;
    },

    onLocationChange (value, propName) {
      this.where[propName] = value;
    },

    formatLocation (str) {
      return (str || '').split(',').slice(0,2).join(',')
    },

    getFeedItem (feed_id, field) {
      for (let i = 0, len = this.feedItems.length; i < len; i++) {
        const c = this.feedItems[i]
        if (c.id === feed_id) return (field ? c[field] : c)
      }
      return '-'
    },

    sortBy (value, order) {
      this.loadItems({order: [[value, order]]})
    },

    loadFeedItems () {
      this.$services.api_programmatic.feed.search({where: { status: [1] }})
        .then(response => {
          this.feedItems = response.data
          const filters = []
          this.feedItems.map(item => {
            if (item.fileformat_fields && item.fileformat_fields.length > 0) {
              item.fileformat_fields.map(field => {
                if (field.custom) {
                  if ((filters).indexOf(field.key) === -1) {
                    filters.push(field.key)
                  }
                }
              })
            }
          })
          this.customFilters = filters
        })
    },

    loadSectorItems() {
      this.$services.api_programmatic.job_sector.search()
        .then(response => {
          this.sectorItems = response.data
        })
    },

    loadOccupationsItems() {
      this.$services.api_programmatic.job_occupation.search()
        .then(response => {
          this.occupationItems = response.data
        })
    },

    // Request

    setWhereValue () {
      if (this.jobTab === undefined) { this.whereValue = {status: 1} }
      if (this.jobTab === "draft") { this.whereValue = {draft: true} }
      if (this.jobTab === "archived") { this.whereValue = {status: 0} }
      if (this.jobTab === "deleted") { this.whereValue = {status: -1} }
      this.loadItems()
    },

    removeItems () {
      this.showEdit = false
      this.$router.push('/xtrading/jobs/')
      this.loadItems()
    },

    searchItems (element) {
      this.selected = []
      this.loading = true
      // debounce request
      if (this.timeout) clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.loadItems()
      }, 100)
    },

    removeCityFromSelection (index) {
      this.cities.splice(index, 1)
      this.searchItems()
    },

    loadItems (options = {}) {
      this.loading = true
      this.$queryFilters().save()
      const defaultOptions = {
        order: [['updated_at', 'DESC']],
        mode: 'list',
        page: this.page || 1,
        limit: this.limit,
      }

      let query = this.$queryFormat({
        where: {status: 1},//this.whereValue,
        options: Object.assign({}, defaultOptions, options ),
      })

      if (query.where.location_city) {
        query.where.location = this.cities.map(item => item.name)
        query.where.location_city = this.cities.map(item => item.name)
        query.where.location_city_code = this.cities.map(item => item.code)
      }
      if (query.where.xtramile_sector_id && typeof query.where.xtramile_sector_id === 'object') {
        query.where.xtramile_sector_id = query.where.xtramile_sector_id.map(v => v.id)
      }
      if (query.where.xtramile_occupation_id && typeof query.where.xtramile_occupation_id === 'object') {
        query.where.xtramile_occupation_id = query.where.xtramile_occupation_id.map(v => v.id)
      }

      this.$emit('childData', {queryList: query} )
      this.$services.api_programmatic.job.search(query)
        .then(response => {
          if (response.data) {
            const td = v => {
              v.feed = { title: this.getFeedItem(v.feed_id, 'title') }
              return v
            }
            this.items = response.data.items.map(td)
            this.count = response.data.count
            this.offset = response.data.offset
            this.limit = response.data.limit
          } else {
            this.items = []
            this.count = 0
            this.offset = 0
            this.limit = 0
          }
          this.updateSelected()
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
        .finally(() => {
          this.loading = false
        })
    },

    changePage (page) {
      this.page = +page
      this.searchItems()
    },

    confirmRemoveItems () {
      const ids = this.selected.map(v => v.id)
      this.$services.api_programmatic.job.remove({ where: { id: ids }, options: {limit:ids.length}})
        .then(response => {
          if (response.data) {
            this.$store.dispatch('messenger/add', {type: 'success', code: response.code, message: response.message, time: 3000 })
          }
          this.selected = []
          this.showDialogRemove = false
          this.loadItems()
        })
        .catch(e => {
          this.$store.dispatch('messenger/add', {type: 'error', code: e.code, message: e.message, time: 5000 })
        })
    },

    updateSelected () {
      for (let i = 0, lenI = this.selected.length; i < lenI; i++) {
        const a = this.selected[i]
        for (let j = 0, lenJ = this.items.length; j < lenJ; j++) {
          const b = this.items[j]
          if (a.id === b.id) {
            this.selected[i] = b
            break
          }
        }
      }
    },

    // Mutliple items

    removeSelected () {
      this.showDialogRemove = true
    },

    editSelected () {
      this.showEdit = true
      if (this.selected.length > 0) {
        const item = this.selected[0]
        this.$router.push(`/xtrading/jobs/?edit=${item.id}`)
      }
    },

    selectItem (item) {
      for (let i=0, len = this.selected.length; i < len; i++) {
        if (this.selected[i].id === item.id) return this.selected.splice(i,1)
      }
      return this.selected.push(item)
    },

    selectItems (arr) {
      this.selected = arr
    },

    closeEdit () {
      this.showEdit = false
      this.$router.push('/xtrading/jobs/')
    },

    closePublish () {
      this.showPublish = false
      this.$router.push('/xtrading/jobs/')
    },


    selectAllPages (options) {
      this.loading = true
      const defaultOptions = {
        order: [['updated_at', 'DESC']],
        attributes: ['id', 'updated_at'],
        limit: 100000,
      }
      const query = this.$queryFormat({
        where: {status: 1},
        options: Object.assign({}, defaultOptions, {options}),
      })

      this.$services.api_programmatic.job.search(query)
        .then(response => {
          if (response.data) {
            this.selected = response.data
          }
        })
        .finally(() => {
          this.loading = false
        })

    },

    // Single item

    updateItems() {
      this.loadItems()
    },

    newItem () {
      this.selected = []
      this.showNew = true
    },

    editItem (item) {
      this.selected = [item]
      this.$router.push(`/xtrading/jobs/?edit=${  this.selected[0].id}`)
      this.showEdit = true
    },

    editPermissionItem (item) {
      this.selected = [item]
      this.showDialogPermissionEdit = true
    },

    removeItem (item) {
      this.selected = [item]
      this.showDialogRemove = true
    },

    applyFilters () {
      this.searchItems()
    },

    resetFilters () {
      this.applyFilters()
    },

    searchLocationItems (n) {
      const then = (response) => {
        let data = []
        if (response && response.data) data = response.data
        this.cityItems = data
      }

      if (this.locationSearchTimeout) clearTimeout(this.locationSearchTimeout)
      this.locationLoading = true
      this.locationSearchTimeout = setTimeout(() => {
        if (n) {
          this.$services.api_programmatic
            .location_city.search({where:{title:n}, options: {limit: 10, order:[['level','DESC']]}})
            .then(then)
        }else {
          Promise.resolve().then(then)
        }
      }, 200)
    },

    searchAndClean () {
      this.searchItems()
      this.locationInput = ''
    },
  },
}
</script>

<style lang="scss">
.page-xranking-all-jobs__button-upload {
  margin-top: 10px;
  border-radius: 10px;
  .v-btn__content {
    gap: 5px;
  }
}
</style>
